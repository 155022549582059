<template>
  <div :class="classes">
    <Heading
      v-if="titleContent"
      :content="titleContent"
      size="large"
      tag="h2"
      role="headline"
    />
    <div class="footer-contact-items">
      <FooterContactItem
        heading="Häufige Fragen"
        paragraph="Hier findest du eine schnelle Antwort auf die meisten Fragen."
        icon="faq"
        button-content="FAQ"
      />
      <FooterContactItem
        heading="Kundenportal"
        paragraph="Hier kannst du deine Daten einsehen und bequem verwalten."
        icon="userMale"
        button-content="Kundenportal"
      />
      <FooterContactItem
        v-if="parseInt(numberOfCards) > 2"
        heading="Chat Messenger"
        paragraph="Wir sind für dich da und beantworten gerne deine Fragen."
        icon="bubble"
        button-content="Chat öffnen"
        heading-status="green"
        id="chat-container--footer"
      />
      <FooterContactItem
        v-if="parseInt(numberOfCards) > 3"
        heading="Hotline"
        paragraph="Du bist Interessent:in?"
        paragraph-popover="Mo. bis Fr. 08:00 bis 20:00 Uhr<br />Sa. 08:00 bis 15:00 Uhr"
        icon="headphone"
        button-content="0800 / 44 11 800"
        second-paragraph="Du bist bereits Kund:in?"
        second-button-content="0221 / 789 658 00"
        second-paragraph-popover="Mo. bis Fr. 08:00 bis 20:00 Uhr<br />Sa. 08:00 bis 15:00 Uhr"
        heading-status="green"
      />
    </div>
  </div>
</template>

<script>
import Heading from 'ewi-ui/components/1-atoms/Heading';
import FooterContactItem from '../../2-molecules/FooterContactItem/FooterContactItem.vue';
import { reactive, computed } from 'vue';
import './FooterContact.scss';

export default {
  name: 'CardSlider',
  components: {
    FooterContactItem,
    Heading,
  },
  props: {
    titleContent: {
      type: String,
      default: 'Title',
    },
    numberOfCards: {
      type: String,
      default: '4',
    },
  },
  setup(props, { emit }) {
    props = reactive(props);
    return {
      classes: computed(() => ({
        'footer-contact': true,
      })),
    };
  },
};
</script>
