import FooterNavigation from './FooterNavigation.vue';
import type { Meta, StoryObj } from '@storybook/vue3';

const meta = {
  title: 'Organisms/Footer Navigation',
  tags: ['autodocs'],
  component: FooterNavigation,
  render: (args) => ({
    components: { FooterNavigation },
    setup() {
      return { args };
    },
    template:
      '<div class="bg-dark color-white"><FooterNavigation v-bind="args" /></div>',
  }),
  argTypes: {
    variant: {
      control: { type: 'select' },
      options: ['Full', 'Minimal'],
      description: 'Select variant',
    },
    skipToDataProtectionText: {
      control: { type: 'text' },
      description: 'Text for skip to data protection link',
    },
  },
  args: {},
} satisfies Meta<typeof FooterNavigation>;

export default meta;
type Story = StoryObj<typeof meta>;

const primaryNav = [
  {
    title: 'Strom',
    links: [
      {
        href: 'strom/stromtarife',
        title: 'Stromtarife von E WIE EINFACH',
        text: 'Stromtarife',
        ariaLabel: 'Stromtarife von E WIE EINFACH',
      },
      {
        href: 'strom/oekostromanbieter',
        title: 'Ökostromanbieter – einfach und günstig',
        text: 'Ökostromanbieter',
        ariaLabel: 'Ökostromanbieter – einfach und günstig',
      },
      {
        href: 'strom/regional',
        title: 'Regionaler Strom',
        text: 'Regionaler Strom',
        ariaLabel: 'Regionaler Strom',
      },
    ],
  },
  {
    title: 'Nachhaltigkeit',
    links: [
      {
        href: 'nachhaltigkeit/klimaschutzprojekte',
        title: 'Wir unterstützen Klimaprojekte',
        text: 'Klimaschutzprojekte',
        ariaLabel: 'Wir unterstützen Klimaprojekte',
      },
      {
        href: 'Energie-Sparen',
        title:
          'Energiesparen - Infos, Tipps und Tricks um den Verbrauch zu senken und Energie zu sparen',
        text: 'Energiesparen',
        ariaLabel:
          'Energiesparen - Infos, Tipps und Tricks um den Verbrauch zu senken und Energie zu sparen',
      },
      {
        href: 'esports/greenergaming',
        title: 'Greener Gaming',
        text: 'Greener Gaming',
        ariaLabel: 'Greener Gaming',
      },
    ],
  },
  {
    title: 'Service',
    links: [
      {
        href: 'https://www.ewieeinfach-freundewerben.de/?pk_campaign=EWI-Website-Footer-Service',
        title: 'E WIE EINFACH kündigen - Online-Kündigungsformular',
        text: 'Kündigen',
        ariaLabel: 'E WIE EINFACH kündigen - Online-Kündigungsformular',
      },
      {
        href: 'service/kuendigung',
        title:
          'Energiesparen - Infos, Tipps und Tricks um den Verbrauch zu senken und Energie zu sparen',
        text: 'Energiesparen',
        ariaLabel:
          'Energiesparen - Infos, Tipps und Tricks um den Verbrauch zu senken und Energie zu sparen',
      },
      {
        href: 'faq',
        title: 'FAQ',
        text: 'FAQ',
        ariaLabel: 'FAQ',
      },
      {
        href: 'service/vertriebspartner-werden',
        title: 'Vertriebspartner:in werden &amp; schnell Geld verdienen',
        text: 'Vertriebspartner:in werden',
        ariaLabel: 'Vertriebspartner:in werden &amp; schnell Geld verdienen',
      },
    ],
  },
  {
    title: 'Gas',
    links: [
      {
        href: 'gas/gasanbieter',
        title: 'Gasanbieter: vergleichen, wechseln &amp; sparen',
        text: 'Gasanbieter',
        ariaLabel: 'Gasanbieter: vergleichen, wechseln &amp; sparen',
      },
      {
        href: 'gas/regional',
        title: 'Regionales Gas',
        text: 'Regionales Gas',
        ariaLabel: 'Regionales Gas',
      },
    ],
  },
  {
    title: 'Über uns',
    links: [
      {
        href: 'ueber-uns/auszeichnungen',
        title: 'Auszeichnungen',
        text: 'Auszeichnungen',
        ariaLabel: 'Auszeichnungen',
      },
      {
        href: 'ueber-uns/newsroom',
        title: 'Presse und Downloads',
        text: 'Presse und Downloads',
        ariaLabel: 'Presse und Downloads',
      },
      {
        href: 'ueber-uns/jobs-und-karriere',
        title: 'Jobs',
        text: 'Jobs',
        ariaLabel: 'Jobs',
      },
      {
        href: 'ueber-uns/rechtliches',
        title: 'Rechtliches',
        text: 'Rechtliches',
        ariaLabel: 'Rechtliches',
      },
      {
        href: 'ueber-uns/kontakt',
        title: 'Dein Kontakt zu E WIE EINFACH',
        text: 'Kontakt',
        ariaLabel: 'Dein Kontakt zu E WIE EINFACH',
      },
      {
        href: 'esports',
        title: 'E-Sports',
        text: 'E-Sports',
        ariaLabel: 'E-Sports',
      },
    ],
  },
];
const socialLinks = [
  {
    class: 'instagram',
    href: 'https://www.instagram.com/e_wie_einfach/',
    title: 'instagram',
    svgIcon: 'socialInstagram',
    ariaLabel: 'instagram',
  },
  {
    class: 'youtube',
    href: 'https://www.youtube.com/ewieeinfach',
    title: 'youtube',
    svgIcon: 'socialYoutube',
    ariaLabel: 'youtube',
  },
  {
    class: 'facebook',
    href: 'https://www.facebook.com/ewieeinfach',
    title: 'facebook',
    svgIcon: 'socialFacebook',
    ariaLabel: 'facebook',
  },
  {
    class: 'twitter',
    href: 'https://twitter.com/e_wie_einfach',
    title: 'twitter',
    svgIcon: 'socialTwitter',
    ariaLabel: 'twitter',
  },
  {
    class: 'linkedin',
    href: 'https://www.linkedin.com/company/e-wie-einfach-strom-und-gas-gmbh/',
    title: 'linkedin',
    svgIcon: 'socialLinkedin',
    ariaLabel: 'linkedin',
  },
];
const legalLinks = [
  {
    class: 'first',
    href: 'ueber-uns/impressum',
    title: 'Impressum',
    ariaLabel: 'Impressum',
  },
  {
    class: '',
    href: 'ueber-uns/datenschutz',
    title: 'Geschäftskunden',
    ariaLabel: 'Geschäftskunden',
  },
  {
    class: '',
    href: 'ueber-uns/impressum',
    title: 'Impressum',
    ariaLabel: 'Impressum',
  },
  {
    class: 'last',
    href: 'javascript:void(0)',
    title: 'Cookies',
    ariaLabel: 'Cookies',
    onclick: 'UC_UI.showSecondLayer();',
  },
];

export const Default: Story = {
  args: {
    ...meta.args,
    variant: 'Full',
    footerPrimaryNav: primaryNav,
    legalLinks: legalLinks,
    socialList: socialLinks,
    skipToDataProtectionText: 'Zu den rechtlichen Pflichtangaben',
  },
};
export const Minimal: Story = {
  args: {
    ...meta.args,
    variant: 'Minimal',
    footerPrimaryNav: primaryNav,
    legalLinks: legalLinks,
    socialList: socialLinks,
  },
};
