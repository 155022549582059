import { createApp } from 'vue';
import HomePage from './ui/components/5-pages/HomePage/HomePage.vue';
import './ui/styles/main.scss';
import { createPinia } from 'pinia';
import { makeServer } from './mockServer.js';
import '@varlet/touch-emulator';

const pinia = createPinia();

if (import.meta.env.DEV === true) {
  console.log('Starting MirageJS mock server.');
  makeServer();
}

let appMountElement = document.getElementById('app');
if (appMountElement) {
  const app = createApp(HomePage);
  app.use(pinia).mount(appMountElement);
}
