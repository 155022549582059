<template>
  <div class="accordionItem" :class="activeClasses">
    <div @click="toggleAccordion">
      <button
        :id="accordionTitleId"
        type="button"
        :aria-expanded="isOpenLocal"
        :aria-controls="accordionContentId"
      >
        <Heading
          role="title"
          size="medium"
          :content="title"
          tag="h4"
          class="accordionItem__title"
        />

        <span class="accordionItem__arrow">
          <svg-icon name="arrow" />
        </span>
      </button>
    </div>

    <div class="wrapper">
      <div
        :id="accordionContentId"
        class="accordionItem__content"
        role="region"
        :aria-labelledby="accordionTitleId"
      >
        <div class="accordionItem__content_inner" :inert="!isOpenLocal">
          <RichText :rich-text-string="content" />
          <div class="accordionItem__links">
            <LinkButton
              v-if="links && links.length > 0"
              v-for="link in links"
              :key="uuidv4()"
              :type="link.type"
              :url="link.url"
              :target="link.target"
              :aria-label="link.ariaLabel"
            >
              <template #icon>
                <SvgIcon size="xs" name="arrowRight" />
              </template>
              {{ link.title }}
            </LinkButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import './AccordionItem.scss';
import { computed, ref, watch } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import Heading from 'ewi-ui/components/1-atoms/Heading';
import SvgIcon from 'ewi-ui/components/1-atoms/Icon/SvgIcon';
import RichText from 'ewi-ui/components/1-atoms/RichText';
import LinkButton, {
  LinkButtonProps,
} from 'ewi-ui/components/1-atoms/LinkButton';

const accordionContentId = uuidv4();
const accordionTitleId = uuidv4();

export interface AccordionItemProps {
  title: string;
  isOpen?: boolean;
  border?: boolean;
  content: string;
  links?: LinkButtonProps[];
}

const props = withDefaults(defineProps<AccordionItemProps>(), {
  title: 'Accordion Item',
  isOpen: false,
  border: true,
  content: '',
});

const isOpenLocal = ref(props.isOpen);
const toggleAccordion = () => {
  isOpenLocal.value = !isOpenLocal.value;
};

const activeClasses = computed(() => ({
  'accordionItem--open': isOpenLocal.value,
  accordionItem__no_border: props.border !== true,
}));

watch(
  () => props.isOpen,
  (newValue) => {
    isOpenLocal.value = newValue;
  },
);
</script>
