import Image from './Image.vue';
import type { Meta, StoryObj } from '@storybook/vue3';

const meta: Meta<typeof Image> = {
  title: 'Atoms/Media/Image',
  tags: ['atoms', 'autodocs'],
  component: Image,
  argTypes: {
    imageCaption: {
      control: 'text',
      description: 'Caption for the image',
    },
    imageCaptionPosition: {
      control: 'select',
      description: 'Caption position',
      options: ['left', 'center', 'right'],
    },
    imageRoundedCorners: {
      control: 'boolean',
      description: 'Round corners for image',
    },
    imageForceAspectRatio: {
      control: 'select',
      description: 'Force aspect ratio on image (cropping will occur)',
      options: ['4_3', '16_9', '1_1', '3_4'],
    },
    imageForceAspectRatioMobile: {
      control: 'select',
      description:
        'Force aspect ratio on image for mobile (cropping will occur)',
      options: ['4_3', '16_9', '1_1', '3_4'],
    },
    imageHorizontalFocus: {
      control: 'select',
      description: 'Image horizontal focus',
      options: ['left', 'center', 'right'],
      if: { arg: 'imageForceAspectRatio', truthy: true },
    },
    imageVerticalFocus: {
      control: 'select',
      description: 'Image vertical focus',
      options: ['top', 'center', 'bottom'],
      if: { arg: 'imageForceAspectRatio', truthy: true },
    },
    singleSrc: {
      control: 'text',
      description: 'Single source image',
    },
  },
  args: {
    imageCaption: 'Lorem ipsum copyrights.',
    imageCaptionPosition: 'left',
    imageRoundedCorners: true,
    imageForceAspectRatio: '',
    imageForceAspectRatioMobile: '',
    imageHorizontalFocus: '',
    imageVerticalFocus: '',
    singleSrc: '',
  },
} satisfies Meta<typeof Image>;

export default meta;
type Story = StoryObj<typeof meta>;

export const Default: Story = {
  args: {
    ...meta.args,
  },
};
