<template>
  <div class="richText" v-html="props.richTextString"></div>
</template>

<script lang="ts" setup>
import './RichText.scss';

export interface RichTextProps {
  richTextString: string;
}
defineOptions({
  name: 'RichText',
});
const props = withDefaults(defineProps<RichTextProps>(), {
  richTextString: 'Rich Text',
});
// Add your component logic here
</script>
