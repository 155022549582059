import MediaBlock from './MediaBlock.vue';
import { Default as Image } from 'ewi-ui/components/1-atoms/Media/Image/Image.stories.ts';
import { MediaBlockArgTypes } from '../../../argTypes.config';

export default {
  title: 'Molecules/Media Block',
  tags: ['autodocs'],
  component: MediaBlock,
  argTypes: MediaBlockArgTypes,
};

const Template = (args) => ({
  components: { MediaBlock },
  setup() {
    return { args };
  },
  template: '<div class="container"><MediaBlock v-bind="args" /></div>',
});
export const Default = Template.bind({});
Default.args = {
  background: '',
  textTestVariant: 'short',
  firstButtonContent: 'Mehr Informationen',
  secondButtonContent: 'enabled',
  ...Image.args,
};

export const Light = Template.bind({});
Light.args = {
  background: 'light',
  textTestVariant: 'short',
  firstButtonContent: 'Mehr Informationen',
  imageForceAspectRatio: '1_1',
};
