<template>
  <header class="header-main bg-dark color-white">
    <div class="container">
      <a href="#mainNavigation" class="skip-to-content" tabindex="1">{{
        skipToContentText
      }}</a>
      <div class="header" :class="classes">
        <div class="logo">
          <a href="/" title="E WIE EINFACH heim" tabindex="1">
            <SvgIcon name="logo" size="auto" />
          </a>
        </div>

        <nav
          class="header-nav-primary"
          v-if="variant !== 'Minimal'"
          aria-label="Hauptnavigation"
        >
          <HeaderNavigationPrimary v-bind="props.primaryNavigation" />
          <div class="container">
            <div class="app-buttons">
              <a
                href="https://apps.apple.com/de/app/e-wie-einfach/id1024176043"
                title="Die E WIE EINFACH App im Apple App Store herunterladen."
              >
                <SvgIcon name="dlAppStore" size="auto" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=de.ewieeinfach.apps.csc&amp;hl=de"
                title="Die E WIE EINFACH App im Google Play Store herunterladen."
              >
                <SvgIcon name="dlGPlay" size="auto" />
              </a>
            </div>
          </div>
        </nav>

        <div class="header-nav-search-wrap">
          <SearchForm />
        </div>

        <nav
          aria-label="Shop-Navigation für die Suche, den Warenkorb und das Kundenportal"
          class="header-nav-secondary"
        >
          <ul>
            <li v-if="variant !== 'Minimal'">
              <button
                class="secondary-nav-item js_open_search blank-button"
                aria-label="Suche"
              >
                <span class="icon icon-search">
                  <svg-icon name="search" />
                </span>
              </button>
            </li>
            <li v-if="variant !== 'Minimal'">
              <a
                href="/warenkorb"
                class="secondary-nav-item"
                aria-label="Warenkorb"
              >
                <span class="icon icon-cart">
                  <svg-icon name="cart" />
                </span>
              </a>
            </li>
            <li>
              <a
                href="//mein.e-wie-einfach.de"
                class="secondary-nav-item"
                aria-label="Kundenportal"
              >
                <span class="icon icon-user">
                  <svg-icon name="userMale" />
                </span>
                <span class="label">Kundenportal</span>
              </a>
            </li>
            <li>
              <button
                class="header-mobile-nav js_open_nav blank-button secondary-nav-item"
                v-if="variant !== 'Minimal'"
                aria-label="Menü"
              >
                <span class="icon icon-burger">
                  <svg-icon name="burger" />
                  <span class="label">Menü</span>
                </span>
              </button>
            </li>
          </ul>
        </nav>

        <div class="header-search-form">
          <svg-icon name="arrowRight" />
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { HeaderNavigationPrimary, type HeaderNavigationPrimaryProps } from '.';
import SearchForm from 'ewi-ui/components/2-molecules/SearchForm/SearchForm.vue';
import SvgIcon from 'ewi-ui/components/1-atoms/Icon/SvgIcon/SvgIcon.vue';
import { computed } from 'vue';
import HeaderNavigation from './HeaderNavigation.js';
import './HeaderNavigation.scss';

export interface HeaderNavigationProps {
  variant?: 'Full' | 'Minimal';
  primaryNavigation: HeaderNavigationPrimaryProps;
  skipToContentText: string;
}

const props = withDefaults(defineProps<HeaderNavigationProps>(), {
  variant: 'Full',
  primaryNavigation: () => ({
    navItems: [],
  }),
});

const classes = computed(() => ({
  'header-navigation': true,
}));

onMounted(() => {
  HeaderNavigation.init();
});
</script>
