<template>
  <div :class="classes">
    <div class="m-icon-text-item__image">
      <RoundIcon :icon="icon" theme="dark" size="sm" iconStyle="circle" />
    </div>
    <div class="m-icon-text-item__text-wrapper">
      <Heading
        tag="h3"
        role="title"
        size="large"
        :content="title"
        class="m-icon-text-item__text-wrapper__headline"
      />
      <Paragraph
        v-if="paragraph"
        :content="paragraph"
        size="large"
        class="m-icon-text-item__text-wrapper__paragraph"
      />
    </div>
  </div>
</template>

<script>
import './IconTextItem.scss';
import { reactive, computed } from 'vue';
import Heading from 'ewi-ui/components/1-atoms/Heading';
import Paragraph from '../../1-atoms/Paragraph/Paragraph.vue';
import RoundIcon from 'ewi-ui/components/2-molecules/RoundIcon';

export default {
  name: 'IconTextItem',
  components: {
    Heading,
    Paragraph,
    RoundIcon,
  },
  props: {
    icon: {
      type: String,
      default: 'house',
    },
    title: {
      type: String,
      default: '',
    },
    paragraph: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    props = reactive(props);
    return {
      classes: computed(() => ({
        'm-icon-text-item': true,
      })),
    };
  },
};
</script>
