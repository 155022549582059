<template>
  <div :class="classes">
    <Image image-force-aspect-ratio="4_3" roundedCorners="false" />
    <div class="card-slider-item__inner">
      <div class="card-slider-item__inner__text">
        <Paragraph v-if="label" :content="labelContent" size="small" />
        <Heading
          v-if="title"
          :content="titleContent"
          role="headline"
          size="small"
          tag="h3"
        />
      </div>
      <div class="card-slider-item__inner__cta">
        <a href="http://www.google.com">
          <BaseButton v-if="button" :label="buttonContent" button-type="filled" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import './CardSliderItem.scss';
import { reactive, computed } from 'vue';
import Image from 'ewi-ui/components/1-atoms/Media/Image/Image.vue';
import Paragraph from '../../1-atoms/Paragraph/Paragraph.vue';
import Heading from 'ewi-ui/components/1-atoms/Heading';
import BaseButton from 'ewi-ui/components/1-atoms/BaseButton/BaseButton.vue';
import cardSliderItem from './CardSliderItem.js';

export default {
  name: 'CardSliderItem',
  components: {
    Image,
    Paragraph,
    Heading,
    BaseButton,
  },
  props: {
    label: {
      type: Boolean,
      default: true,
    },
    labelContent: {
      type: String,
      default: 'Freundinnen werben',
    },
    title: {
      type: Boolean,
      default: true,
    },
    titleContent: {
      type: String,
      default: 'Sicher dir jetzt 50€ für jede erfolgreiche Ameldung.',
    },
    button: {
      type: Boolean,
      default: true,
    },
    buttonContent: {
      type: String,
      default: 'Mehr Informationen',
    },
  },
  mounted() {
    cardSliderItem.init();
  },
  setup(props, { emit }) {
    props = reactive(props);
    return {
      classes: computed(() => ({
        'card-slider-item': true,
      })),
    };
  },
};
</script>
