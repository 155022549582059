<template>
  <footer class="container footer">
    <a
      v-if="skipToDataProtectionText"
      href="#footer-legal-list"
      class="skip-to-legal"
      tabindex="0"
      >{{ skipToDataProtectionText }}</a
    >
    <div class="row">
      <div class="col">
        <div :class="classes">
          <div class="footer-top border-bottom-dark">
            <MobileAppTeaser />
          </div>
          <div class="footer-mid">
            <nav class="sitenavList" aria-label="Footer Primary Navigation">
              <FooterPrimaryNav
                v-for="menu in footerPrimaryNav"
                :key="menu.title"
                :title="menu.title"
                :links="menu.links"
              />
            </nav>
          </div>

          <div
            class="footer-bottom"
            :class="{ 'border-top-dark': variant?.toLowerCase() === 'full' }"
          >
            <nav
              id="footer-legal-list"
              class="legalList"
              aria-label="Footer Legal Links"
            >
              <LegalLinks :legalLinks="legalLinks" />
            </nav>
            <nav class="socialList" aria-label="Footer Social Links">
              <SocialList :links="socialList" />
            </nav>
          </div>
          <p class="copyright">© E WIE EINFACH 2023</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import MobileAppTeaser from 'ewi-ui/components/3-organisms/FooterNavigation/MobileAppTeaser.vue';
import FooterPrimaryNav, {
  FooterPrimaryNavProps,
} from './FooterPrimaryNav.vue';
import SocialList, { SocialListItem } from './SocialList.vue';
import LegalLinks, { LegalLink } from './LegalLinks.vue';
import { computed } from 'vue';
import './FooterNavigation.scss';

export interface FooterNavigationProps {
  variant?: string;
  footerPrimaryNav?: FooterPrimaryNavProps[];
  legalLinks?: LegalLink[];
  socialList?: SocialListItem[];
  skipToDataProtectionText?: string;
}
const props = withDefaults(defineProps<FooterNavigationProps>(), {
  variant: '',
  footerPrimaryNav: () => [] as FooterPrimaryNavProps[],
  legalLinks: () => [] as LegalLink[],
  socialList: () => [] as SocialListItem[],
});

const classes = computed(() => ({
  'footer-navigation': true,
  [`footer-${props.variant?.toLowerCase()}`]: true,
}));
</script>
