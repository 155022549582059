import AppBanner from './AppBanner.vue';
import { EWI_BACKGROUNDS } from '../../../const.config';

export default {
  title: 'Molecules/App Banner',
  tags: ['autodocs'],
  component: AppBanner,
  argTypes: {
    background: {
      control: 'select',
      description: 'Background color',
      options: EWI_BACKGROUNDS,
    },
    title: {
      control: 'boolean',
      description: 'Title',
    },
    titleContent: {
      control: 'text',
      if: { arg: 'title', truthy: true },
    },
    bodyText: {
      control: 'boolean',
      description: 'Body Text',
    },
    bodyTextContent: {
      control: 'text',
      if: { arg: 'bodyText', truthy: true },
    },
    textContainerWidth: {
      control: { type: 'select' },
      options: ['narrow', 'medium', 'wide'],
    },
    textContainerPosition: {
      control: { type: 'select' },
      options: ['left', 'center', 'right'],
    },
    textAlignment: {
      control: { type: 'select' },
      description: 'Horizontal text alignment',
      options: ['left', 'center', 'right'],
    },
  },
};

const Template = (args) => ({
  components: { AppBanner },
  setup() {
    return { args };
  },
  template: '<div class="container"><AppBanner v-bind="args" /></div>',
});

export const Lightblue = Template.bind({});
Lightblue.args = {
  background: 'lightblue',
  title: true,
  titleContent: 'DEINE APP. DEINE ENERGIE.',
  textContainerWidth: 'medium',
  textContainerPosition: 'left',
  textAlignment: 'left',
  bodyText: true,
  bodyTextContent:
    'Du findest Energieanbieter langweilig? Wir auch. Deswegen machen wir Energie jetzt anders. Hol dir #DEINEENERGIE als App und verwalte deinen Vertrag unkompliziert selbst.',
};

export const Lightgrey = Template.bind({});
Lightgrey.args = {
  background: 'lightgrey',
  title: true,
  titleContent: 'DEINE APP. DEINE ENERGIE.',
  textContainerWidth: 'medium',
  textContainerPosition: 'left',
  textAlignment: 'left',
  bodyText: true,
  bodyTextContent:
    'Du findest Energieanbieter langweilig? Wir auch. Deswegen machen wir Energie jetzt anders. Hol dir #DEINEENERGIE als App und verwalte deinen Vertrag unkompliziert selbst.',
};

export const Light = Template.bind({});
Light.args = {
  background: 'light',
  title: true,
  titleContent: 'DEINE APP. DEINE ENERGIE.',
  textContainerWidth: 'medium',
  textContainerPosition: 'left',
  textAlignment: 'left',
  bodyText: true,
  bodyTextContent:
    'Du findest Energieanbieter langweilig? Wir auch. Deswegen machen wir Energie jetzt anders. Hol dir #DEINEENERGIE als App und verwalte deinen Vertrag unkompliziert selbst.',
};

Light.parameters = {
  design: {
    type: 'figma',
    url: 'https://www.figma.com/design/xXlc2up8YJE6Zw6Vv6foGz/EWI-Design?node-id=1471-34661',
  },
};

export const Dark = Template.bind({});
Dark.args = {
  background: 'dark',
  title: true,
  titleContent: 'DEINE APP. DEINE ENERGIE.',
  textContainerWidth: 'medium',
  textContainerPosition: 'left',
  textAlignment: 'left',
  bodyText: true,
  bodyTextContent:
    'Du findest Energieanbieter langweilig? Wir auch. Deswegen machen wir Energie jetzt anders. Hol dir #DEINEENERGIE als App und verwalte deinen Vertrag unkompliziert selbst.',
};
