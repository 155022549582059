<template>
  <div class="ewimx-wrapper">
    <PromoLine v-bind="promoLineArgs" />

    <HeaderNavigation v-bind="headerNavigationArgs" />

    <div id="mainNavigation" class="custom">
      <div id="hero_teaser">
        <div class="inside">
          <div class="mod_article edge p-b-7 block">
            <div class="block_inner">
              <div class="container-edge">
                <div class="row">
                  <div class="col">
                    <StageHero v-bind="stageHeroArgs" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <main class="ewimx-main">
      <div class="mod_article bg_black block">
        <div class="block_inner">
          <div class="container">
            <div class="row">
              <div class="col">
                <Calculator v-bind="calculatorArgs" />
                <Information v-bind="informationArgs" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mod_article col-12 block">
        <div class="block_inner">
          <div class="container">
            <div class="row">
              <div class="col">
                <CardSlider v-bind="cardSliderArgs" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mod_article col-12 block">
        <div class="block_inner">
          <div class="container">
            <div class="row">
              <div class="col">
                <TextBlock v-bind="textBlockArgs" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mod_article col-12 block">
        <div class="block_inner">
          <div class="container">
            <div class="row">
              <div class="col">
                <AppBanner v-bind="appBannerArgs" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mod_article col-12 block">
        <div class="block_inner">
          <div class="container">
            <div class="row">
              <div class="col">
                <Accordion v-bind="accordionArgs" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mod_article col-12 block">
        <div class="block_inner">
          <div class="container">
            <div class="row">
              <div class="col">
                <MediaBlock v-bind="mediaBlockArgs" />
                <MediaBlock v-bind="mediaBlockArgs" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mod_nodes">
        <div class="node_wrapper container">
          <FooterContact v-bind="footerContactArgs" />
        </div>
      </div>
    </main>

    <div class="bg-dark color-white">
      <FooterNavigation v-bind="footerNavigationArgs" />
    </div>

    <fabButton v-bind="fabButtonArgs">
      <template #content>
        <Calculator v-bind="calculatorArgs" />
      </template>
    </fabButton>
  </div>
</template>

<script>
import 'ewi-ui/styles/globals.scss';
import { reactive, computed } from 'vue';
import PromoLine from 'ewi-ui/components/2-molecules/PromoLine/PromoLine.vue';
import { Default as PromoLineArgs } from 'ewi-ui/components/2-molecules/PromoLine/PromoLine.stories.ts';

import HeaderNavigation from 'ewi-ui/components/3-organisms/HeaderNavigation/HeaderNavigation.vue';

import StageHero from '../../3-organisms/StageHero/StageHero.vue';
import { Default as StageHeroArgs } from '../../3-organisms/StageHero/StageHero.stories.js';

import Calculator from 'ewi-ui/components/3-organisms/Calculator/Calculator.vue';
import { HalfView as CalculatorArgs } from 'ewi-ui/components/3-organisms/Calculator/Calculator.stories.ts';

import IconText from '../../2-molecules/IconText/IconText.vue';
import { Default as IconTextArgs } from '../../2-molecules/IconText/IconText.stories.js';

import Information from 'ewi-ui/components/2-molecules/Information/Information.vue';
import { Lightgrey as InformationArgs } from 'ewi-ui/components/2-molecules/Information/Information.stories.ts';

import CardSlider from '../../3-organisms/CardSlider/CardSlider.vue';
import { Default as CardSliderArgs } from '../../3-organisms/CardSlider/CardSlider.stories.js';

import MediaBlock from '../../2-molecules/MediaBlock/MediaBlock.vue';
import { Default as MediaBlockArgs } from '../../2-molecules/MediaBlock/MediaBlock.stories.js';

import AppBanner from '../../2-molecules/AppBanner/AppBanner.vue';
import { Lightblue as AppBannerArgs } from '../../2-molecules/AppBanner/AppBanner.stories.js';

import TextBlock from '../../2-molecules/TextBlock/TextBlock.vue';
import { HomePage as TextBlockArgs } from '../../2-molecules/TextBlock/TextBlock.stories.js';

import FooterContact from 'ewi-ui/components/3-organisms/FooterContact/FooterContact.vue';
import { Default as FooterContactArgs } from 'ewi-ui/components/3-organisms/FooterContact/FooterContact.stories.js';

import FooterNavigation from 'ewi-ui/components/3-organisms/FooterNavigation/FooterNavigation.vue';
import { Default as FooterNavigationArgs } from 'ewi-ui/components/3-organisms/FooterNavigation/FooterNavigation.stories.ts';

import Accordion from 'ewi-ui/components/3-organisms/Accordion/Accordion.vue';
import FabButton from 'ewi-ui/components/1-atoms/FabButton/FabButton.vue';
import { DarkFab as FabButtonArgs } from 'ewi-ui/components/1-atoms/FabButton/FabButton.stories.ts';

export default {
  name: 'HomePage',
  components: {
    PromoLine,
    HeaderNavigation,
    StageHero,
    Information,
    CardSlider,
    FooterContact,
    TextBlock,
    MediaBlock,
    AppBanner,
    Calculator,
    IconText,
    FooterNavigation,
    Accordion,
    FabButton,
  },
  props: {},
  data() {
    return {
      promoLineArgs: {},
      stageHeroArgs: {},
      calculatorArgs: {},
      IconTextArgs: {},
      informationArgs: {},
      cardSliderArgs: {},
      footerContactArgs: {},
      textBlockArgs: {},
      mediaBlockArgs: {},
      appBannerArgs: {},
      accordionArgs: {},
      fabButtonArgs: {},
      footerNavigationArgs: {},
    };
  },
  setup(props) {
    props = reactive(props);
    return {};
  },
  created() {
    this.promoLineArgs = PromoLineArgs.args;
    this.stageHeroArgs = StageHeroArgs.args;
    this.calculatorArgs = CalculatorArgs.args;
    this.IconTextArgs = IconTextArgs.args;
    this.informationArgs = InformationArgs.args;
    this.cardSliderArgs = CardSliderArgs.args;
    this.footerContactArgs = FooterContactArgs.args;
    this.textBlockArgs = TextBlockArgs.args;
    this.mediaBlockArgs = MediaBlockArgs.args;
    this.appBannerArgs = AppBannerArgs.args;
    this.footerNavigationArgs = FooterNavigationArgs.args;
    this.accordionArgs = {
      title: 'Accordion Title',
      items: [
        {
          isOpen: false,
          title: ' Accordion Item',
          border: true,
          content:
            '\n       <h3>Das ist eine Headline</h3>\n      <p>Ab dem 01.01.2021 wird ein einheitlicher Preis für den Ausstoß von CO2 erhoben mit dem Ziel, die aus Emissionen resultierenden Auswirkungen wie z.B. die globale Erwärmung zu verringern und die deutschen Klimaziele zu erreichen. Betroffen hiervon sind Benzin, Gas, Diesel und Heizöl. Die CO2-Belastung wurde von der Bundesregierung einheitlich festgelegt, Grundlage ist das Brennstoffemissionshandelsgesetz (BEHG). Die Einnahmen aus der CO2-Belastung sollen für eine Entlastung von Pendlern genutzt werden</p>\n     \n      ',
          links: [
            {
              default: 'LinkButton',
              url: '#',
              ariaLabel: 'LinkButton',
              type: 'primary',
            },
            {
              default: 'LinkButton',
              url: '#',
              ariaLabel: 'LinkButton',
              type: 'outlined',
            },
          ],
        },
        {
          isOpen: false,
          title: 'Accordion Item',
          border: true,
          content:
            '\n      <img src="/layout/storybook/sb-assets/friends.png" alt="Example image"/>\n      <h3>Das ist eine Headline</h3>\n      <p>Ab dem 01.01.2021 wird ein einheitlicher Preis für den Ausstoß von CO2 erhoben mit dem Ziel, die aus Emissionen resultierenden Auswirkungen wie z.B. die globale Erwärmung zu verringern und die deutschen Klimaziele zu erreichen. Betroffen hiervon sind Benzin, Gas, Diesel und Heizöl. Die CO2-Belastung wurde von der Bundesregierung einheitlich festgelegt, Grundlage ist das Brennstoffemissionshandelsgesetz (BEHG). Die Einnahmen aus der CO2-Belastung sollen für eine Entlastung von Pendlern genutzt werden</p>\n     \n      ',
          links: [
            {
              default: 'LinkButton',
              url: '#',
              ariaLabel: 'LinkButton',
              type: 'primary',
            },
            {
              default: 'LinkButton',
              url: '#',
              ariaLabel: 'LinkButton',
              type: 'outlined',
            },
          ],
        },
        {
          isOpen: true,
          title: 'Open Accordion Item',
          border: true,
          content:
            '\n      <img src="/layout/storybook/sb-assets/friends.png" alt="Example image"/>\n      <h3>Das ist eine Headline</h3>\n      <p>Ab dem 01.01.2021 wird ein einheitlicher Preis für den Ausstoß von CO2 erhoben mit dem Ziel, die aus Emissionen resultierenden Auswirkungen wie z.B. die globale Erwärmung zu verringern und die deutschen Klimaziele zu erreichen. Betroffen hiervon sind Benzin, Gas, Diesel und Heizöl. Die CO2-Belastung wurde von der Bundesregierung einheitlich festgelegt, Grundlage ist das Brennstoffemissionshandelsgesetz (BEHG). Die Einnahmen aus der CO2-Belastung sollen für eine Entlastung von Pendlern genutzt werden</p>\n     \n      ',
          links: [
            {
              default: 'LinkButton',
              url: '#',
              ariaLabel: 'LinkButton',
              type: 'primary',
            },
            {
              default: 'LinkButton',
              url: '#',
              ariaLabel: 'LinkButton',
              type: 'outlined',
            },
          ],
        },
      ],
      theme: 'blue',
    };
    this.headerNavigationArgs = {
      variant: 'Full',
      skipToContentText: 'Zum Inhalt springen',
      primaryNavigation: {
        variant: 'Full',
        navItems: [
          {
            title: 'Strom',
            href: 'strom',
            text: 'Strom',
            subnav: {
              title: 'Strom',
              links: [
                { title: 'Strom', href: 'strom', text: 'Strom' },
                {
                  title: 'Stromtarife von E WIE EINFACH',
                  href: 'strom/stromtarife',
                  text: 'Stromtarife',
                },
                {
                  title: 'Gewerbestrom » Vergleiche Gewerbestromtarife',
                  href: 'geschaeftskunden/gewerbestrom',
                  text: 'Gewerbestrom',
                },
                {
                  title: 'Strom-Ratgeber',
                  href: 'strom/ratgeber',
                  text: 'Strom-Ratgeber',
                },
              ],
            },
          },
          {
            title: 'Gas',
            href: 'gas',
            text: 'Gas',
            subnav: {
              title: 'Gas',
              links: [
                { title: 'Gas', href: 'gas', text: 'Gas' },
                {
                  title: 'Gastarife',
                  href: 'gas/gastarife',
                  text: 'Gastarife',
                },
                {
                  title: 'MeinSmartTarif',
                  href: 'gas/meinsmarttarif-gas',
                  text: 'MeinSmartTarif',
                },
                {
                  title: 'Gewerbegas',
                  href: 'geschaeftskunden/gewerbegas',
                  text: 'Gewerbegas',
                },
              ],
            },
          },
          {
            title: 'Nachhaltigkeit',
            href: 'nachhaltigkeit',
            text: 'Nachhaltigkeit',
          },
          {
            title: 'Service',
            href: 'service',
            text: 'Service',
            subnav: {
              title: 'Service',
              links: [
                { title: 'Service', href: 'service', text: 'Service' },
                {
                  title: 'Marktlage',
                  href: 'aktuelle-lage-energiemarkt/ubersicht-der-aktuellen-lage',
                  text: 'Marktlage',
                },
                {
                  title: 'Kontakt',
                  href: 'service/kontakt',
                  text: 'Kontakt',
                },
                {
                  title: 'Chat',
                  href: 'service/chat',
                  text: 'Chat',
                },
                {
                  title: 'FAQ',
                  href: 'faq',
                  text: 'FAQ',
                },
                {
                  title: 'E WIE EINFACH App',
                  href: 'service/app',
                  text: 'E WIE EINFACH App',
                },
                {
                  title: 'Umzug',
                  href: 'service/umzug',
                  text: 'Umziehen',
                },
                {
                  title: 'E WIE EINFACH kündigen - Online-Kündigungsformular',
                  href: 'service/kuendigung',
                  text: 'Kündigen',
                },
                {
                  title: 'Freund:innen werben',
                  href: 'https://www.ewieeinfach-freundewerben.de/?pk_campaign=EWI-Website-Navigation-Service',
                  text: 'Freund:innen werben',
                },
                {
                  title: 'Vertriebspartner:in werden & schnell Geld verdienen',
                  href: 'service/vertriebspartner-werden',
                  text: 'Vertriebspartner:in werden',
                },
              ],
            },
          },
          {
            title: 'Über uns',
            href: 'ueber-uns',
            text: 'Über uns',
            subnav: {
              title: 'Über uns',
              links: [
                { title: 'Über uns', href: 'ueber-uns', text: 'Über uns' },
                {
                  title: 'Auszeichnungen',
                  href: 'ueber-uns/auszeichnungen',
                  text: 'Auszeichnungen',
                  ariaLabel: 'Auszeichnungen',
                },
                {
                  title: 'Jobs',
                  href: 'ueber-uns/jobs-und-karriere',
                  text: 'Jobs',
                  ariaLabel: 'Jobs',
                },
                {
                  title: 'Presse und Downloads',
                  href: 'ueber-uns/newsroom',
                  text: 'Presse und Downloads',
                  ariaLabel: 'Presse und Downloads',
                },
                {
                  title: 'Kontakt',
                  href: 'ueber-uns/kontakt',
                  text: 'Kontakt',
                },
                {
                  title: 'Datenschutz',
                  href: 'ueber-uns/datenschutz',
                  text: 'Datenschutz',
                  ariaLabel: 'Datenschutz',
                },
                {
                  title: 'Impressum',
                  href: 'ueber-uns/impressum',
                  text: 'Impressum',
                  ariaLabel: 'Impressum',
                },
              ],
            },
          },
        ],
      },
    };
    this.fabButtonArgs = FabButtonArgs.args;
  },
};
</script>
