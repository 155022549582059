import Information from '.';
import { EWI_COLORS, EWI_BACKGROUNDS } from 'ewi-ui-helpers/const.config';
import type { Meta, StoryObj } from '@storybook/vue3';

const meta = {
  title: 'Molecules/Information',
  component: Information,
  render: (args) => ({
    components: { Information },
    setup() {
      return { args };
    },
    template: '<div class="container"><Information v-bind="args" /></div>',
  }),
  tags: ['autodocs'],
  argTypes: {
    background: {
      control: 'select',
      description: 'Background color',
      options: EWI_BACKGROUNDS,
    },
    textContainerWidth: {
      control: { type: 'select' },
      options: ['narrow', 'medium', 'wide'],
    },
    textContainerPosition: {
      control: { type: 'select' },
      options: ['left', 'center', 'right'],
    },
    textAlignment: {
      control: { type: 'select' },
      description: 'Horizontal text alignment',
      options: ['left', 'center', 'right'],
    },
    headlineContent: {
      control: 'text',
    },
    headlineColor: {
      control: 'select',
      options: EWI_COLORS,
    },
    paragraphContent: {
      control: 'text',
    },
    firstButtonContent: {
      control: 'text',
    },
    secondButtonContent: {
      control: 'text',
    },
  },
  args: {
    background: 'lightgrey',
    textContainerWidth: 'medium',
    textContainerPosition: 'left',
    textAlignment: 'left',
    headlineContent: 'Information zur Marktlage',
    headlineColor: 'black',
    paragraphContent:
      'Auf dem Energiemarkt ist aktuell einiges in Bewegung. Infos zur Strom-/Gaspreisbremse und Soforthilfe zum Dezemberabschlag sowie zur Preisentwicklung und den aktuellen gesetzlichen Änderungen findest du hier.',
    firstButtonContent: 'Zeig mir mehr Infos',
    secondButtonContent: 'secondary button',
  },
} satisfies Meta<typeof Information>;

export default meta;

type Story = StoryObj<typeof meta>;

export const Lightgrey: Story = {
  args: {
    ...meta.args,
  },
};

export const Light: Story = {
  args: {
    ...meta.args,
    background: 'light',
  },
  parameters: {
    design: {
      type: 'figma',
      url: 'https://www.figma.com/file/xXlc2up8YJE6Zw6Vv6foGz/EWI-Design?type=design&node-id=348-7359&t=ZNZYb7szasAe28J6-4',
    },
  },
};

export const Dark: Story = {
  args: {
    ...meta.args,
    background: 'dark',
    headlineColor: 'white',
  },
};

export const Lightblue: Story = {
  args: {
    ...meta.args,
    background: 'lightblue',
    firstButtonContent: 'Jetzt informieren',
  },
};
