<template>
  <div :class="classes">
    <p v-html="content"></p>
  </div>
</template>

<script>
import './Paragraph.scss';
import { computed } from 'vue';

export default {
  name: 'Paragraph',
  props: {
    content: {
      type: String,
      default: '',
    },
    align: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'medium',
    },
    white: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    return {
      classes: computed(() => ({
        paragraph: true,
        [`paragraph--${props.size}`]: true,
        [`paragraph--${props.align}`]: true,
        'paragraph--white': props.white,
      })),
    };
  },
};
</script>
