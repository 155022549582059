<template>
  <div :class="classes">
    <div class="imageWrap">
      <Image
        :force-aspect-ratio="imageForceAspectRatio"
        caption=""
        :caption-position="imageCaptionPosition"
        :image-rounded-corners="imageRoundedCorners"
      />
    </div>

    <div class="textWrap">
      <h1>
        <Heading
          v-if="heading_1"
          :content="heading_1"
          tag="span"
          role="display"
          size="medium"
        />

        <Heading
          v-if="heading_2"
          :content="heading_2"
          tag="span"
          role="extra"
          size="large"
        />
      </h1>

      <Heading
        v-if="heading_3"
        :content="heading_3"
        tag="p"
        role="title"
        size="large"
      />

      <div class="buttons" v-if="buttonElement">
        <BaseButton
          v-if="buttonElement"
          :button-type="buttonConfiguration"
          state="default"
          :label="buttonContent"
          theme="light"
        />
      </div>

      <div class="awards" v-if="awardsElement">
        <figure class="image_container">
          <img src="/layout/storybook/sb-assets/siegel_1.svg?url" alt="" />
        </figure>
        <figure class="image_container">
          <img src="/layout/storybook/sb-assets/siegel_2.svg?url" alt="" />
        </figure>
        <figure class="image_container">
          <img src="/layout/storybook/sb-assets/siegel_3.svg?url" alt="" />
        </figure>
        <figure class="image_container">
          <img src="/layout/storybook/sb-assets/siegel_4.svg?url" alt="" />
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
import './StageHero.scss';
import { reactive, computed } from 'vue';
import Heading from 'ewi-ui/components/1-atoms/Heading';
import BaseButton from 'ewi-ui/components/1-atoms/BaseButton/BaseButton.vue';
import Image from 'ewi-ui/components/1-atoms/Media/Image/Image.vue';

export default {
  name: 'StageHero',
  components: {
    Heading,
    BaseButton,
    Image,
  },
  props: {
    heading_1: {
      type: String,
      default: '',
    },
    heading_2: {
      type: String,
      default: '',
    },
    heading_3: {
      type: String,
      default: '',
    },
    textContainerWidth: {
      type: String,
      default: '',
    },
    textContainerPosition: {
      type: String,
      default: '',
    },
    textHorizontalAlignment: {
      type: String,
      default: 'left',
    },
    textVerticalAlignment: {
      type: String,
      default: 'center',
    },
    mobileTextContainerWidth: {
      type: String,
      default: '',
    },
    mobileTextContainerPosition: {
      type: String,
      default: '',
    },
    mobileTextHorizontalAlignment: {
      type: String,
      default: '',
    },
    mobileTextVerticalAlignment: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: 'white',
    },
    imageRoundedCorners: {
      type: Boolean,
      default: true,
    },
    buttonElement: {
      type: Boolean,
      default: true,
    },
    buttonConfiguration: {
      type: String,
      default: 'filled',
    },
    buttonContent: {
      type: String,
      default: '-',
    },
    awardsElement: {
      type: Boolean,
      default: true,
    },
    hasOverlay: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    props = reactive(props);
    return {
      classes: computed(() => ({
        'm-stage-hero': true,
        'has-overlay': props.hasOverlay,
        [`text-horizontal-${props.textHorizontalAlignment}`]:
          props.textHorizontalAlignment !== '',
        [`text-vertical-${props.textVerticalAlignment}`]:
          props.textVerticalAlignment !== '',
        [`text-container-width-${props.textContainerWidth}`]:
          props.textContainerWidth !== '',
        [`text-container-position-${props.textContainerPosition}`]:
          props.textContainerPosition !== '',
        [`mobile-text-horizontal-${props.mobileTextHorizontalAlignment}`]:
          props.mobileTextHorizontalAlignment !== '',
        [`mobile-text-vertical-${props.mobileTextVerticalAlignment}`]:
          props.mobileTextVerticalAlignment !== '',
        [`mobile-text-container-width-${props.mobileTextContainerWidth}`]:
          props.mobileTextContainerWidth !== '',
        [`mobile-text-container-position-${props.mobileTextContainerPosition}`]:
          props.mobileTextContainerPosition !== '',
        [`color-${props.textColor}`]: props.textColor !== '',
      })),
    };
  },
};
</script>
