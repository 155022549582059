<template>
  <div :class="classes">
    <div class="row">
      <div class="col-12 col-md-7 imageWrap">
        <Image v-bind="$props" />
      </div>
      <div class="col-12 col-md-5 d-flex flex-column justify-content-center">
        <TextBlock
          :test-variant="textTestVariant"
          :first-button-content="firstButtonContent"
          :second-button-content="secondButtonContent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import './MediaBlock.scss';
import { reactive, computed, toRaw } from 'vue';
import TextBlock from '../../2-molecules/TextBlock/TextBlock.vue';
import Image from 'ewi-ui/components/1-atoms/Media/OldImage/Image.vue';

export default {
  name: 'MediaBlock',
  components: {
    TextBlock,
    Image,
  },
  props: {
    background: {
      type: String,
      default: 'lightgrey',
    },
    textTestVariant: {
      type: String,
      default: 'short',
    },
    firstButtonContent: {
      type: String,
      default: '',
    },
    secondButtonContent: {
      type: String,
      default: '',
    },
    imagePosition: {
      type: String,
      default: '',
    },
    ...Image.props,
  },

  setup(props, { emit }) {
    props = reactive(props);
    return {
      classes: computed(() => ({
        'm-media-block': true,
        [`bg-${props.background}`]: props.background !== '',
        [`image-position-${props.imagePosition}`]: props.imagePosition !== '',
      })),
    };
  },
};
</script>
