<template>
  <div :id="id ? id : null" :class="classes">
    <div class="inner">
      <div class="heading-wrap">
        <RoundIcon :icon="icon" theme="dark" size="sm" icon-style="circle" />

        <Heading
          v-if="heading"
          :content="heading"
          role="headline"
          size="small"
          tag="h3"
          :status="headingStatus"
        />
      </div>

      <div class="cta-wrap">
        <div>
          <p v-if="paragraph">
            <span v-html="paragraph"></span>
          </p>
          &nbsp;
          <InfoButton v-if="paragraphPopover" :content="paragraphPopover" />
        </div>

        <a v-if="buttonContent" href="#js">
          <BaseButton :label="buttonContent" :button-type="buttonStyle" />
        </a>

        <div v-if="secondParagraph || secondButton" class="second">
          <p v-if="secondParagraph">
            <span v-html="secondParagraph"></span>
          </p>
          &nbsp;
          <InfoButton v-if="paragraphPopover" :content="paragraphPopover" />
        </div>

        <a v-if="secondButtonContent" href="#js">
          <BaseButton
            :label="secondButtonContent"
            :button-type="secondButtonStyle"
            class="cta"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import './FooterContactItem.scss';
import { reactive, computed } from 'vue';
import RoundIcon from 'ewi-ui/components/2-molecules/RoundIcon';
import Heading from 'ewi-ui/components/1-atoms/Heading';
import BaseButton from 'ewi-ui/components/1-atoms/BaseButton/BaseButton.vue';
import InfoButton from 'ewi-ui/components/2-molecules/InfoButton/InfoButton.vue';

export default {
  name: 'FooterContactItem',
  components: {
    RoundIcon,
    Heading,
    BaseButton,
    InfoButton,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'leaf',
    },
    heading: {
      type: String,
      default: '',
    },
    headingStatus: {
      type: String,
      default: '',
    },
    paragraph: {
      type: String,
      default: '',
    },
    paragraphPopover: {
      type: String,
      default: '',
    },
    textHorizontalAlignment: {
      type: String,
      default: 'center',
    },
    textVerticalAlignment: {
      type: String,
      default: '',
    },
    buttonContent: {
      type: String,
      default: '',
    },
    buttonStyle: {
      type: String,
      default: 'outline',
    },
    secondParagraph: {
      type: String,
      default: '',
    },
    secondParagraphPopover: {
      type: String,
      default: '',
    },
    secondButtonContent: {
      type: String,
      default: '',
    },
    secondButtonStyle: {
      type: String,
      default: 'outline',
    },
  },
  setup(props, { emit }) {
    props = reactive(props);
    return {
      classes: computed(() => ({
        'footer-contact-item': true,
        [`text-horizontal-align-${props.textHorizontalAlignment}`]:
          props.textHorizontalAlignment !== '',
        [`text-vertical-align-${props.textVerticalAlignment}`]:
          props.textVerticalAlignment !== '',
      })),
    };
  },
};
</script>
