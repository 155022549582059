import { computed } from 'vue';
import type {
  Typescale,
  TextAlignment,
  ComponentTheme,
  AvailabilityStatus,
  MarginBottom,
} from './types';

export function useTypescaleClass(props: Typescale) {
  const typescaleClass = computed(() => {
    return `md-typescale-${props.role}-${props.size}`;
  });

  return {
    typescaleClass,
  };
}

export function useAlignmentClass(props: TextAlignment) {
  const alignmentClass = computed(() => {
    return props.align ? `text-align-${props.align}` : '';
  });

  return {
    alignmentClass,
  };
}
export function useMarginBottomClass(props: MarginBottom) {
  const marginBottomClass = computed(() => {
    return props.marginBottom ? `margin-bottom-${props.marginBottom}` : '';
  });

  return {
    marginBottomClass,
  };
}

export function useComponentThemeClass(props: ComponentTheme) {
  const componentThemeClass = computed(() => {
    return `u-color--${props.theme}`;
  });

  return {
    componentThemeClass,
  };
}

export function useAvailabilityStatusClass(props: AvailabilityStatus) {
  const availabilityStatusClass = computed(() => {
    return props.status ? `status-${props.status}` : '';
  });

  return {
    availabilityStatusClass,
  };
}
