<template>
  <figure class="image" :class="classes">
    <picture>
      <template v-if="singleSrc !== ''">
        <img :src="singleSrc" alt="" width="103" height="103" />
      </template>

      <template v-if="test === 'left' && singleSrc === ''">
        <source
          srcset="
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-7196514f.webp 1x,
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-a83681a9.webp 2x
          "
          media="(max-width:390px)"
          type="image/webp"
          width="390"
          height="390"
        />
        <source
          srcset="
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-7bc67de9.jpg 1x,
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-da1bf31b.jpg 2x
          "
          media="(max-width:390px)"
          width="390"
          height="390"
        />
        <source
          srcset="
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-a706d850.webp 1x,
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-b5387fad.webp 2x
          "
          media="(max-width:575px)"
          type="image/webp"
          width="575"
          height="575"
        />
        <source
          srcset="
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-41b1583b.jpg 1x,
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-28f12a35.jpg 2x
          "
          media="(max-width:575px)"
          width="575"
          height="575"
        />
        <source
          srcset="
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-ac172197.webp 1x,
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-f2653798.webp 2x
          "
          media="(max-width:768px)"
          type="image/webp"
          width="768"
          height="415"
        />
        <source
          srcset="
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-908fcf8a.jpg 1x,
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-628820d2.jpg 2x
          "
          media="(max-width:768px)"
          width="768"
          height="415"
        />
        <source
          srcset="
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-7df20b97.webp 1x,
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-f2653798.webp 1.935x
          "
          media="(max-width:992px)"
          type="image/webp"
          width="992"
          height="518"
        />
        <source
          srcset="
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-a7d9cb1d.jpg 1x,
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-ed325d1a.jpg 1.935x
          "
          media="(max-width:992px)"
          width="992"
          height="518"
        />
        <source
          srcset="
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-4a8f7414.webp 1x,
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-2cda2a28.webp 1.6x
          "
          type="image/webp"
          width="1200"
          height="530"
        />
        <img
          src="/layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-c4804f5b.jpg"
          srcset="
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-c4804f5b.jpg 1x,
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern-2c3f202e.jpg 1.6x
          "
          alt="Frau steht im pinken Licht mit Kopfhörern auf und schaut auf ihr Handy"
          title="Frau mit Handy und Kopfhörern"
          width="1200"
          height="530"
        />
      </template>

      <template v-if="test !== 'left' && singleSrc === ''">
        <source
          srcset="
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-46005a42.webp 1x,
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-9b37df3a.webp 2x
          "
          media="(max-width:390px)"
          type="image/webp"
          width="390"
          height="390"
        />
        <source
          srcset="
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-ec9b9e2b.jpg 1x,
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-df984b35.jpg 2x
          "
          media="(max-width:390px)"
          width="390"
          height="390"
        />
        <source
          srcset="
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-b3d8a308.webp 1x,
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-c6075947.webp 2x
          "
          media="(max-width:575px)"
          type="image/webp"
          width="575"
          height="575"
        />
        <source
          srcset="
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-a11961c5.jpg 1x,
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-2a2d5024.jpg 2x
          "
          media="(max-width:575px)"
          width="575"
          height="575"
        />
        <source
          srcset="
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-4d738596.webp 1x,
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-d8f338ea.webp 2x
          "
          media="(max-width:768px)"
          type="image/webp"
          width="768"
          height="415"
        />
        <source
          srcset="
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-97fa3e18.jpg 1x,
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-d07a8c04.jpg 2x
          "
          media="(max-width:768px)"
          width="768"
          height="415"
        />
        <source
          srcset="
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-e264bf22.webp 1x,
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-acd88df6.webp 1.935x
          "
          media="(max-width:992px)"
          type="image/webp"
          width="992"
          height="518"
        />
        <source
          srcset="
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-f7c3e31c.jpg 1x,
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-0482a50d.jpg 1.935x
          "
          media="(max-width:992px)"
          width="992"
          height="518"
        />
        <source
          srcset="
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-51526026.webp 1x,
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-b9290a20.webp 1.6x
          "
          type="image/webp"
          width="1200"
          height="530"
        />
        <img
          src="/layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-13e381f5.jpg"
          srcset="
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-13e381f5.jpg 1x,
            /layout/storybook/sb-assets/Frau_mit_Handy_und_Kopfhoerern_mirrored-0e01983b.jpg 1.6x
          "
          alt=""
          width="1200"
          height="530"
        />
      </template>
    </picture>
    <figcaption v-if="imageCaption !== ''" class="image-caption">
      {{ imageCaption }}
    </figcaption>
  </figure>
</template>

<script>
import './Image.scss';
import { reactive, computed } from 'vue';
export default {
  name: 'Image',
  props: {
    imageCaption: {
      type: String,
      default: '',
    },
    imageRoundedCorners: {
      type: Boolean,
      default: true,
    },
    imageCaptionPosition: {
      type: String,
      default: 'left',
    },
    imageForceAspectRatio: {
      type: String,
      default: '',
    },
    imageForceAspectRatioMobile: {
      type: String,
      default: '',
    },
    imageHorizontalFocus: {
      type: String,
      default: '',
    },
    imageVerticalFocus: {
      type: String,
      default: '',
    },
    singleSrc: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    props = reactive(props);
    return {
      classes: computed(() => ({
        image__roundedCorners: props.imageRoundedCorners === true,
        [`image__caption__${props.imageCaptionPosition}`]: true,
        [`image__ratio__${props.imageForceAspectRatio}`]:
          props.imageForceAspectRatio !== '',
        [`mobile__image__ratio__${props.imageForceAspectRatioMobile}`]:
          props.imageForceAspectRatioMobile !== '',
        [`image__vfocus__${props.imageVerticalFocus}`]:
          props.imageVerticalFocus !== '',
        [`image__hfocus__${props.imageHorizontalFocus}`]:
          props.imageHorizontalFocus !== '',
      })),
    };
  },
};
</script>
