import FabButton from '.';
import type { Meta, StoryObj } from '@storybook/vue3';
// @ts-ignore
import Calculator from 'ewi-ui/components/3-organisms/Calculator/Calculator.vue';
import {
  FullView as CalculatorArgs,
  GreyTheme,
} from 'ewi-ui/components/3-organisms/Calculator/Calculator.stories.ts';

const meta = {
  title: 'Atoms/FabButton',
  component: FabButton,
  render: (args, { globals }) => ({
    components: { FabButton, Calculator },
    setup() {
      return { args, globals, CalculatorArgs };
    },
    template: `
    <fabButton v-bind="args">
      <template #content>
        <Calculator v-bind="CalculatorArgs.args" />
      </template>
    </fabButton>`,
  }),
  tags: ['autodocs'],
  argTypes: {
    theme: {
      control: { type: 'select' },
      description: 'The type of the input.',
      options: ['light', 'dark'],
    },
  },
  args: {
    buttonType: 'primary',
    label: 'Tarif berechnen',
    theme: 'dark',
  },
} satisfies Meta<typeof FabButton>;

export default meta;
type Story = StoryObj<typeof meta>;

export const DarkFab: Story = {
  ...meta,
  args: meta.args,
};

export const LightFab: Story = {
  ...meta,
  args: { ...meta.args, theme: 'light' },
};

export const InnerContentGreyFab: Story = {
  ...meta,
  args: { ...meta.args, theme: 'light', innerContentTheme: 'grey' },
  render: (args, { globals }) => ({
    components: { FabButton, Calculator },
    setup() {
      return { args, globals, GreyTheme };
    },
    template: `
    <fabButton v-bind="args">
      <template #content>
        <Calculator v-bind="GreyTheme.args" />
      </template>
    </fabButton>`,
  }),
};
