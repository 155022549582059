<template>
  <div :class="classes">
    <IconTextItem
      v-for="(items, index) in numberOfChildren"
      :key="index"
      :title="title"
      :paragraph="paragraph"
      :icon="icon"
    />
  </div>
</template>

<script>
import './IconText.scss';
import { reactive, computed } from 'vue';
import IconTextItem from '../../1-atoms/IconTextItem/IconTextItem.vue';

export default {
  name: 'IconText',
  components: {
    IconTextItem,
  },
  props: {
    title: {
      type: String,
      default: 'Nachhaltig',
    },
    paragraph: {
      type: String,
      default: '100% Ökostrom und CO2 kompensiertes Gas.',
    },
    icon: {
      type: String,
      default: 'leaf',
    },
    numberOfChildren: {
      type: Number,
      default: 4,
    },
  },

  setup(props, { emit }) {
    props = reactive(props);
    return {
      classes: computed(() => ({
        'm-icon-text': true,
      })),
    };
  },
};
</script>
