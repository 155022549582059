import StageHero from './StageHero.vue';
import { EWI_COLORS } from '../../../const.config';

// More on how to set up stories at: https://storybook.js.org/docs/html/writing-stories/introduction
export default {
  title: 'Organisms/Stage Hero',
  tags: ['autodocs'],
  component: StageHero,
  argTypes: {
    heading_1: {
      control: 'text',
      description: 'first heading - leave empty if not needed',
    },
    heading_2: {
      control: 'text',
      description: 'second heading - leave empty if not needed',
    },
    heading_3: {
      control: 'text',
      description: 'third heading - leave empty if not needed',
    },
    textContainerWidth: {
      control: { type: 'select' },
      options: ['narrow', 'medium', 'wide'],
    },
    textContainerPosition: {
      control: { type: 'select' },
      options: ['left', 'center', 'right'],
    },
    textHorizontalAlignment: {
      control: { type: 'select' },
      description: 'Horizontal text alignment',
      options: ['left', 'center', 'right'],
    },
    textVerticalAlignment: {
      control: { type: 'select' },
      description: 'Vertical text alignment',
      options: ['top', 'center', 'bottom'],
    },
    mobileTextContainerWidth: {
      control: { type: 'select' },
      options: ['narrow', 'medium', 'wide'],
    },
    mobileTextContainerPosition: {
      control: { type: 'select' },
      options: ['left', 'center', 'right'],
    },
    mobileTextHorizontalAlignment: {
      control: { type: 'select' },
      description: 'Horizontal text alignment',
      options: ['left', 'center', 'right'],
    },
    mobileTextVerticalAlignment: {
      control: { type: 'select' },
      description: 'Vertical text alignment',
      options: ['top', 'center', 'bottom'],
    },

    textColor: {
      control: { type: 'select' },
      description: 'Text color',
      options: EWI_COLORS,
    },
    imageRoundedCorners: {
      control: { type: 'boolean' },
      description: 'Round corners for image',
    },
    buttonElement: {
      control: { type: 'boolean' },
      description: 'Enable button element',
    },
    buttonConfiguration: {
      control: { type: 'select' },
      description: 'The configuration of the button.',
      options: ['filled', 'outlined', 'text', 'elevated', 'tonal'],
      if: { arg: 'buttonElement', truthy: true },
    },
    buttonContent: {
      control: 'text',
      if: { arg: 'buttonElement', truthy: true },
    },
    awardsElement: {
      control: { type: 'boolean' },
      description: 'Enable awards image.',
    },
    hasOverlay: {
      control: { type: 'boolean' },
      description: 'Enable overlay.',
    },
  },
};

export const Default = {
  args: {
    heading_1: 'SEIT 15 JAHREN:',
    heading_2: 'E WIE ENERGIE',
    heading_3:
      'Unser Service und unsere Strom- und Gas-Tarifewerden regelmäßig ausgezeichnet. Darauf sind wir natürlich besonders stolz!',
    textContainerWidth: 'narrow',
    textContainerPosition: 'left',
    textHorizontalAlignment: 'left',
    textVerticalAlignment: 'center',
    mobileTextContainerWidth: 'wide',
    mobileTextContainerPosition: 'left',
    mobileTextHorizontalAlignment: 'right',
    mobileTextVerticalAlignment: 'top',
    textColor: 'white',
    imageRoundedCorners: false,
    buttonElement: false,
    buttonConfiguration: 'filled',
    buttonContent: 'Call to action',
    awardsElement: true,
    hasOverlay: false,
  },
};
