import FooterContact from './FooterContact.vue';

export default {
  title: 'Organisms/Footer Contact',
  tags: ['autodocs'],
  component: FooterContact,
  argTypes: {
    titleContent: {
      control: 'text',
    },
    numberOfCards: {
      control: 'select',
      options: ['2', '3', '4'],
    },
  },
};

const Template = (args) => ({
  components: { FooterContact },
  setup() {
    return { args };
  },
  template: '<div class="container"><FooterContact v-bind="args" /></div>',
});

export const Default = Template.bind({});
Default.args = {
  titleContent: 'Wir beantworten gerne deine Fragen.',
  numberOfCards: '4',
};

export const ThreeCards = Template.bind({});
ThreeCards.args = {
  titleContent: 'Wir beantworten gerne deine Fragen.',
  numberOfCards: '3',
};

export const TwoCards = Template.bind({});
TwoCards.args = {
  titleContent: 'Wir beantworten gerne deine Fragen.',
  numberOfCards: '2',
};
