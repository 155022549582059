<template>
  <p :class="`paragraph ${typescaleClass} ${alignmentClass}`">
    {{ content }}
  </p>
</template>

<script lang="ts" setup>
import './Paragraph.scss';
import type { Typescale, TextAlignment } from 'ewi-ui-helpers/types';
import {
  useTypescaleClass,
  useAlignmentClass,
} from 'ewi-ui-helpers/computedClasses';
export interface ParagraphProps extends Typescale, TextAlignment {
  content: string;
}

defineOptions({ name: 'Paragraph' });

const props = withDefaults(defineProps<ParagraphProps>(), {
  role: 'body',
  size: 'medium',
  align: 'left',
  content: 'Paragraph',
});

const { typescaleClass } = useTypescaleClass(props);
const { alignmentClass } = useAlignmentClass(props);
</script>
