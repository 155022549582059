<template>
  <component :is="icon" />
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      icons: import.meta.glob(`/layout/storybook/sb-assets/icons/*.svg`),
    };
  },

  computed: {
    icon() {
      const name = this.name;
      return defineAsyncComponent(() =>
        this.icons[`/layout/storybook/sb-assets/icons/${name}.svg`](),
      );
    },
  },
};
</script>
