import PromoLine from '.';
import type { Meta, StoryObj } from '@storybook/vue3';

const meta = {
  title: 'Molecules/PromoLine',
  component: PromoLine,
  render: (args, { globals }) => ({
    components: { PromoLine },
    setup() {
      return { args, globals };
    },
    template: '<promoLine v-bind="args">{{ args.default }}</promoLine>',
  }),
  tags: ['autodocs'],
  argTypes: {
    // Add argTypes here
  },
  args: {
    paragraphContent:
      'Du profitierst bei uns von der Entlastung durch die Preisbremse!',
    buttonContent: 'Button',
  },
} satisfies Meta<typeof PromoLine>;

export default meta;
type Story = StoryObj<typeof meta>;

export const Default: Story = {
  ...meta,
  args: {
    ...meta.args,
  },
};

// Add more stories as needed
