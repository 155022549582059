<template>
  <div :class="classes">
    <template v-if="!testVariant || testVariant == 'rich'">
      <Heading
        content="Spielend einfach Geld verdienen: Das <span class='inline-block'>E WIE EINFACH</span> Affiliate Programm."
        tag="h1"
        role="display"
        size="medium"
      />

      <Heading
        content="Spielend einfach Geld verdienen: Das <span class='inline-block'>E WIE EINFACH</span> Affiliate Programm."
        tag="h2"
        role="headline"
        size="small"
      />

      <p>
        Du möchtest deiner Zielgruppe unsere
        <span class="inline-block">E WIE EINFACH</span> Energietarife empfehlen
        und dafür eine Provision erhalten? Dann ist unser affiliate-Programm
        genau das Richtige für dich! Melde dich dafür ganz einfach bei den
        branchenführenden affiliate-Netzwerken communicationAds oder AWIN an und
        freue dich auf eine attraktive Vergütung. Nach erfolgreicher Anmeldung
        findest Du dort auch weiterführende Informationen sowie unterschiedliche
        Bild und Text-Werbemittel zur Integration auf deiner Website.
      </p>

      <Heading
        content="Zwischen Überschrift 2"
        tag="h2"
        role="headline"
        size="small"
      />

      <p>
        Du möchtest deiner Zielgruppe unsere
        <span class="inline-block">E WIE EINFACH</span> Energietarife empfehlen
        und dafür eine Provision erhalten? Dann ist unser affiliate-Programm
        genau das Richtige für dich! Melde dich dafür ganz einfach bei den
        branchenführenden.
      </p>

      <Heading
        content="Unordered list:"
        tag="h2"
        role="title"
        size="large"
        class="mb-1"
      />

      <ul>
        <li>
          Bis zu 40€ Provision für jeden validen Abschluss eines Neuvertrags der
          Sparten Strom, Wärmestrom oder Gas
        </li>
        <li>
          Erhöhte Sonderprovisionen für einen limitierten Zeitraum auf Anfrage
          möglich
        </li>
        <li>21 Tage Bearbeitungszeitraum der Sales</li>
        <li>Geringe Stornoquote</li>
      </ul>

      <Heading
        content="Ordered list:"
        tag="h2"
        role="title"
        size="large"
        class="mb-1"
      />

      <ol>
        <li>
          Bis zu 40€ Provision für jeden validen Abschluss eines Neuvertrags der
          Sparten Strom, Wärmestrom oder Gas
        </li>
        <li>
          Erhöhte Sonderprovisionen für einen limitierten Zeitraum auf Anfrage
          möglich
        </li>
        <li>21 Tage Bearbeitungszeitraum der Sales</li>
        <li>Geringe Stornoquote</li>
      </ol>

      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Zweck 2</th>
              <th>Erhobene Daten</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>JSESSIONID</td>
              <td>Nachvollziehbarkeit des Login-Status des Moderators</td>
              <td>
                In Verbindung mit Live ContractMainApp_*_connectionId zur
                Verbindungswiederaufnahme Verbindungsart (Websocket, LongPolling)
              </td>
            </tr>
            <tr>
              <td>Live ContractMainApp_*_transportName</td>
              <td>
                Speicherung der Verbindungsart zum Server (bei Aufbau einer
                Live-Verbindung zum Server)
              </td>
              <td>Session-Variablen, Session ID</td>
            </tr>
            <tr>
              <td>Live ContractMainApp_*_connectionId</td>
              <td>
                Speicherung der Verbindungs-ID zum Server (bei Aufbau einer
                Live-Verbindung zum Server)
              </td>
              <td>Verbindungs-ID</td>
            </tr>
            <tr>
              <td>persistUserSettings</td>
              <td>Erhalt der Information zu Benutzer, Gast bei Beitritt</td>
              <td>Beitrittseinstellungen (Vorname, Name, …)</td>
            </tr>
            <tr>
              <td>Live ContractMainApp_welcomeScreenC onfirmed</td>
              <td>
                onfirmed Speicherung der Info, ob der Willkommensbildschirm
                gezeigt wurde (nach Beitritt des Gastes)
              </td>
              <td>Info-Anzeige Willkommensbildschirm</td>
            </tr>
            <tr>
              <td>Language</td>
              <td>Information welche Sprache der Gast / Berater verwendet</td>
              <td>Sprachkürzel</td>
            </tr>
          </tbody>

        </table>
      </div>
    </template>

    <template v-if="testVariant == 'long'">
      <Heading
        content="Ich bin eine lange mehrzeilige Headline"
        tag="h2"
        role="headline"
        size="large"
      />
      <Heading
        content="Ökostrom und Gas vom Energieanbieter: <span class='inline-block'>E WIE EINFACH</span>"
        tag="h3"
        role="title"
        size="medium"
      />
      <p>
        Wir von E WIE EINFACH sind dein Stromanbieter mit 100% Ökostrom. Lerne
        uns außerdem als modernen und digitalen Gasanbieter mit klimaneutralem
        Gas kennen. Der Wechsel zu uns gelingt dir ganz einfach und papierlos.
        Wir von E WIE EINFACH sind dein Stromanbieter mit 100% Ökostrom. Lerne
        uns außerdem als modernen und digitalen Gasanbieter mit klimaneutralem
        Gas kennen. Der Wechsel zu uns gelingt dir ganz einfach und papierlos.
        Wir von E WIE EINFACH sind dein Stromanbieter mit 100% Ökostrom. Lerne
        uns außerdem als modernen und digitalen Gasanbieter mit klimaneutralem
        Gas kennen. Der Wechsel zu uns gelingt dir ganz einfach und papierlos.
        Wir von E WIE EINFACH sind dein Stromanbieter mit 100% Ökostrom. Lerne
        uns außerdem als modernen und digitalen Gasanbieter mit klimaneutralem
        Gas kennen. Der Wechsel zu uns gelingt dir ganz einfach und
        papierlos.Wir von E WIE EINFACH sind dein Stromanbieter mit 100%
        Ökostrom. Lerne uns außerdem als modernen und digitalen Gasanbieter mit
        klimaneutralem Gas kennen. Der Wechsel zu uns gelingt dir ganz einfach
        und papierlos. Wir von E WIE EINFACH sind dein Stromanbieter mit 100%
        Ökostrom. Lerne uns außerdem als modernen und digitalen Gasanbieter mit
        klimaneutralem Gas kennen. Der Wechsel zu uns gelingt dir ganz einfach
        und papierlos.
      </p>
    </template>

    <template v-if="testVariant == 'short'">
      <Heading
        content="Ich bin eine lange mehrzeilige Headline"
        tag="h2"
        role="headline"
        size="large"
      />
      <Heading
        content="Ökostrom und Gas vom Energieanbieter: <span class='inline-block'>E WIE EINFACH</span>"
        tag="h3"
        role="title"
        size="medium"
      />
      <p>
        Wir von E WIE EINFACH sind dein Stromanbieter mit 100% Ökostrom. Lerne
        uns außerdem als modernen und digitalen Gasanbieter mit klimaneutralem
        Gas kennen. Der Wechsel zu uns gelingt dir ganz einfach und papierlos.
      </p>
    </template>

    <template v-if="!testVariant || testVariant == 'home'">
      <Heading
        content="E WIE EINFACH - Dein neuer Energieanbieter"
        tag="h1"
        role="display"
        size="medium"
      />

      <Heading
        content="Ökostrom und Gas vom Energieanbieter: E WIE EINFACH"
        tag="h2"
        role="headline"
        size="small"
      />

      <p>
        Wir von E WIE EINFACH sind dein <a href="">Stromanbieter</a> mit 100%
        Ökostrom. Lerne uns außerdem als modernen und digitalen Gasanbieter mit
        klimaneutralem Gas kennen. Der Wechsel zu uns gelingt dir ganz einfach
        und papierlos, hiernach hast du über unsere App die volle
        Kostenkontrolle und den perfekten Überblick.
      </p>
      <p>
        Bei E WIE EINFACH kannst du außerdem deine Tarife ganz bequem an deine
        jeweiligen Bedürfnisse anpassen. Auch dies geht über unsere App und
        sichert dir eine Energieversorgung zu, wie du sie gerade brauchst. Du
        hast Fragen hierzu? Als servicestarker Energieanbieter helfen wir dir
        gerne persönlich weiter. Verabschiede dich endlich von deinem regionalen
        Versorger und entdecke, wie günstig Ökostrom und Gas heutzutage sein
        können. E WIE EINFACH hilft dir hierbei!
      </p>

      <Heading
        content="Energieanbieter mit attraktiven Wunschprodukten"
        tag="h2"
        role="headline"
        size="small"
      />

      <p>
        Mit unseren ständig wechselnden Wunschprodukten sind wir immer auf Höhe
        der Zeit, was Qualität und Nachhaltigkeit angeht. Die bestätigt neben
        vielen treuen Kund:innen auch eine Vielzahl von Auszeichnungen sowie
        unser TÜV-Zertifikat als Ökostromanbieter.
      </p>
      <p>
        Mit unserem Strom Wunschprodukt und Extras wie unserem Konzept
        "Freund:innen werben" schaffen wir immer neue Anreize, zu uns als fairen
        und nachhaltigen Energieanbieter zu wechseln. Finde auch du nach einem
        Stromvergleich auf unserer Webseite deinen Wunschtarif. Wechsele ganz
        bequem online zu uns, gerne nimmt dir E WIE EINFACH die Kündigung deines
        alten Stromtarifs ab.
      </p>
    </template>

    <div
      class="buttons"
      v-if="firstButtonContent !== '' || secondButtonContent !== ''"
    >
      <a href="#" :target="firstButtonInNewWindow ? '_blank' : '_self'"
        ><BaseButton
          v-if="firstButtonContent !== ''"
          button-type="filled"
          state="default"
          :label="firstButtonContent"
          theme="light"
      /></a>
      <a href="#" :target="secondButtonInNewWindow ? '_blank' : '_self'"
        ><BaseButton
          v-if="secondButtonContent !== ''"
          button-type="outlined"
          state="default"
          :label="secondButtonContent"
          theme="light"
      /></a>
    </div>
  </div>
</template>

<script>
import './TextBlock.scss';
import { reactive, computed } from 'vue';
import Heading from 'ewi-ui/components/1-atoms/Heading';
import BaseButton from 'ewi-ui/components/1-atoms/BaseButton/BaseButton.vue';
export default {
  name: 'TextBlock',
  components: {
    Heading,
    BaseButton,
  },
  data() {
    return {
      content_heading_1:
        'Spielend einfach Geld verdienen: Das E WIE EINFACH Affiliate Programm.',
      content_paragraph_1: '',
    };
  },
  props: {
    testVariant: {
      type: String,
      default: 'rich',
    },
    textContainerWidth: {
      type: String,
      default: 'wide',
    },
    textContainerPosition: {
      type: String,
      default: '',
    },
    textAlignment: {
      type: String,
      default: 'left',
    },
    firstButtonContent: {
      type: String,
      default: '',
    },
    firstButtonInNewWindow: {
      type: Boolean,
      default: false,
    },
    secondButtonContent: {
      type: String,
      default: '',
    },
    secondButtonInNewWindow: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    props = reactive(props);

    return {
      classes: computed(() => ({
        'm-text-block': true,
        [`text-container-width-${props.textContainerWidth}`]:
          props.textContainerWidth !== '',
        [`text-container-position-${props.textContainerPosition}`]:
          props.textContainerPosition !== '',
        [`text-align-${props.textAlignment}`]: props.textAlignment !== '',
      })),
    };
  },
};
</script>
