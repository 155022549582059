<template>
  <div :class="classes">
    <div class="innerWidth">
      <Heading
        v-if="title"
        :content="titleContent"
        size="large"
        tag="h2"
        role="headline"
        transform="upper"
      />
      <Paragraph v-if="bodyText" :content="bodyTextContent" size="large" />
      <div class="app-banner__buttons">
        <a href="">
          <img src="/layout/storybook/sb-assets/appStore.svg?url" alt="" />
        </a>
        <a href="">
          <img src="/layout/storybook/sb-assets/playStore.svg?url" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from 'ewi-ui/components/1-atoms/Heading';
import Paragraph from '../../1-atoms/Paragraph/Paragraph.vue';

import { reactive, computed } from 'vue';

import './AppBanner.scss';

export default {
  name: 'AppBanner',
  components: {
    Heading,
    Paragraph,
  },
  props: {
    background: {
      type: String,
      default: 'lightblue',
    },
    title: {
      type: Boolean,
      default: true,
    },
    titleContent: {
      type: String,
      default: 'Title',
    },
    bodyText: {
      type: Boolean,
      default: true,
    },
    bodyTextContent: {
      type: String,
      default: 'Description',
    },
    textContainerWidth: {
      type: String,
      default: 'medium',
    },
    textContainerPosition: {
      type: String,
      default: '',
    },
    textAlignment: {
      type: String,
      default: 'left',
    },
  },
  setup(props, { emit }) {
    props = reactive(props);
    return {
      classes: computed(() => ({
        'app-banner': true,
        [`bg-${props.background}`]: props.background !== '',
        [`text-container-width-${props.textContainerWidth}`]:
          props.textContainerWidth !== '',
        [`text-container-position-${props.textContainerPosition}`]:
          props.textContainerPosition !== '',
        [`text-align-${props.textAlignment}`]: props.textAlignment !== '',
      })),
    };
  },
};
</script>
