<template>
  <div :class="classes">
    <div class="inner">
      <Paragraph :content="paragraphContent"></Paragraph>
      <a href="#link">
        <BaseButton
          v-if="buttonContent !== ''"
          button-type="outlined"
          :label="buttonContent"
          theme="light"
        />
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import './PromoLine.scss';
import { computed } from 'vue';
import Paragraph from 'ewi-ui/components/1-atoms/Paragraph';
import BaseButton from 'ewi-ui/components/1-atoms/BaseButton';

defineOptions({
  name: 'PromoLine',
});

export interface PromoLineProps {
  paragraphContent?: string;
  buttonContent?: string;
}

withDefaults(defineProps<PromoLineProps>(), {
  paragraphContent: '',
  buttonContent: '',
});

const classes = computed(() => ({
  'm-promo-line': true,
}));
</script>
