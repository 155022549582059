<template>
  <div class="accordion" :class="componentThemeClass">
    <Heading :content="title" tag="h2" role="headline" size="large"
      >{{ props.title }}
    </Heading>
    <div class="accordion__items">
      <AccordionItem
        v-for="item in items"
        :links="item.links"
        :key="item.id"
        :title="item.title"
        :is-open="item.isOpen"
        :content="item.content"
      />
    </div>

    <div class="button__container">
      <LinkButton
        type="outlined"
        url="#"
        target="_blank"
        aria-label="Alle FAQs"
        title="Alle FAQs"
      >
        <template #icon> <SvgIcon size="xs" name="arrowRight" /></template>
      </LinkButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import './Accordion.scss';
import Heading from 'ewi-ui/components/1-atoms/Heading';
import AccordionItem, {
  AccordionItemProps,
} from 'ewi-ui/components/2-molecules/AccordionItem/AccordionItem.vue';
import { computed } from 'vue';
import { ComponentTheme } from 'ewi-ui-helpers/types';
import { useComponentThemeClass } from 'ewi-ui-helpers/computedClasses';

import LinkButton from 'ewi-ui/components/1-atoms/LinkButton';
import SvgIcon from 'ewi-ui/components/1-atoms/Icon/SvgIcon';

export interface AccordionProps extends ComponentTheme {
  title: string;
  items?: (AccordionItemProps & { id?: string })[];
}

const props = withDefaults(defineProps<AccordionProps>(), {
  title: 'Accordion',
  items: () => [] as AccordionItemProps[],
  theme: 'light-grey',
});

const { componentThemeClass } = useComponentThemeClass(props);

const items = computed(() => {
  //todo find guid lib
  return props.items.map((item) => ({
    ...item,
    id: item.id || Math.random().toString(),
  }));
});
</script>
