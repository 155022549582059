<template>
  <div :class="classes">
    <div class="innerWidth">
      <Heading
        v-if="headlineContent"
        tag="h1"
        role="headline"
        size="medium"
        :color="headlineColor"
        :content="headlineContent"
      />

      <p v-if="paragraphContent">{{ paragraphContent }}</p>

      <div v-if="firstButtonContent || secondButtonContent" class="buttons">
        <BaseButton
          v-if="firstButtonContent"
          button-type="filled"
          :label="firstButtonContent"
          theme="light"
        />

        <BaseButton
          v-if="secondButtonContent"
          button-type="outlined"
          :label="secondButtonContent"
          theme="light"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import './Information.scss';
import { computed } from 'vue';
import Heading from 'ewi-ui/components/1-atoms/Heading';
import BaseButton from 'ewi-ui/components/1-atoms/BaseButton';
import { HeadingColor } from 'ewi-ui/components/1-atoms/Heading/Heading.vue';

export interface InformationProps {
  background?: string;
  textContainerWidth?: string;
  textContainerPosition?: string;
  textAlignment?: string;
  headlineContent?: string;
  headlineColor?: HeadingColor;
  paragraphContent?: string;
  firstButtonContent?: string;
  secondButtonContent?: string;
}

const props = withDefaults(defineProps<InformationProps>(), {
  background: '',
  textContainerWidth: '',
  textContainerPosition: '',
  textAlignment: '',
  headlineContent: '',
  headlineColor: '',
  paragraphContent: '',
  firstButtonContent: '',
  secondButtonContent: '',
});

const classes = computed(() => ({
  'm-information': true,
  [`bg-${props.background}`]: props.background !== '',
  [`text-container-width-${props.textContainerWidth}`]:
    props.textContainerWidth !== '',
  [`text-container-position-${props.textContainerPosition}`]:
    props.textContainerPosition !== '',
  [`text-align-${props.textAlignment}`]: props.textAlignment !== '',
}));
</script>
