<template>
  <div class="card-slider">
    <Heading
      v-if="title && titleContent"
      :content="titleContent"
      size="large"
      tag="h2"
      role="headline"
      :align="titleAlignment"
    />
    <div class="card-slider__container">
      <div class="swiper-wrap">
        <swiper-container navigation="true" pagination="true" init="false">
          <swiper-slide>
            <CardSliderItem
              :label="true"
              labelContent="Freundinnen werben"
              :title="true"
              titleContent="Sicher dir jetzt 50€ für jede erfolgreiche Ameldung."
              button="true"
              buttonContent="Mehr Informationen"
            />
          </swiper-slide>
          <swiper-slide>
            <CardSliderItem
              :label="true"
              labelContent="Freundinnen werben"
              title="true"
              titleContent="Sicher dir jetzt 50€ für jede erfolgreiche Ameldung."
              button="true"
              buttonContent="Mehr Informationen"
            />
          </swiper-slide>
          <swiper-slide>
            <CardSliderItem
              label="true"
              labelContent="Freundinnen werben"
              title="true"
              titleContent="Sicher dir jetzt 50€ für jede erfolgreiche Ameldung."
              button="true"
              buttonContent="Mehr Informationen"
            />
          </swiper-slide>
          <swiper-slide>
            <CardSliderItem
              label="true"
              labelContent="Freundinnen werben"
              title="true"
              titleContent="Sicher dir jetzt 50€ für jede erfolgreiche Ameldung."
              button="true"
              buttonContent="Mehr Informationen"
            />
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from 'ewi-ui/components/1-atoms/Heading';
import CardSliderItem from '../../2-molecules/CardSliderItem/CardSliderItem.vue';
import SvgIcon from '../../../helpers/SvgIcon.vue';
import CardSlider from './CardSlider.js';

import { reactive, computed } from 'vue';

import './CardSlider.scss';

export default {
  name: 'CardSlider',
  components: {
    CardSliderItem,
    Heading,
    SvgIcon,
  },
  props: {
    title: {
      type: Boolean,
      default: true,
    },
    titleContent: {
      type: String,
      default: 'Title',
    },
    titleAlignment: {
      type: String,
      default: 'center',
    },
  },
  mounted() {
    CardSlider.init();
  },
  setup(props, { emit }) {
    props = reactive(props);
  },
};
</script>
