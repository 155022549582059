import CardSlider from './CardSlider.vue';

export default {
  title: 'Organisms/Card Slider',
  tags: ['autodocs'],
  component: CardSlider,
  argTypes: {
    title: {
      control: 'boolean',
      description: 'Enable title',
    },
    titleContent: {
      control: 'text',
      if: { arg: 'title', truthy: true },
    },
    titleAlignment: {
      control: { type: 'select' },
      description: 'Heading text alignment',
      options: ['left', 'center', 'right'],
    },
  },
};

const Template = (args) => ({
  components: { CardSlider },
  setup() {
    return { args };
  },
  template: '<div class="container"><CardSlider v-bind="args" /></div>',
});

export const Default = Template.bind({});
Default.args = {
  title: true,
  titleContent: 'Card slider title',
  titleAlignment: 'center',
};
