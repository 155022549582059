import TextBlock from './TextBlock.vue';

export default {
  title: 'Molecules/Text Block',
  tags: ['autodocs'],
  component: TextBlock,
  argTypes: {
    testVariant: {
      control: 'select',
      description: 'Sample test variant',
      options: ['rich', 'long', 'short'],
    },
    textContainerWidth: {
      control: { type: 'select' },
      options: ['narrow', 'medium', 'wide'],
    },
    textContainerPosition: {
      control: { type: 'select' },
      options: ['left', 'center', 'right'],
    },
    textAlignment: {
      control: { type: 'select' },
      description: 'Horizontal text alignment',
      options: ['left', 'center', 'right'],
    },
    firstButtonContent: {
      control: 'text',
    },
    firstButtonInNewWindow: {
      control: 'boolean',
    },
    secondButtonContent: {
      control: 'text',
    },
  },
};
const Template = (args) => ({
  components: { TextBlock },
  setup() {
    return { args };
  },
  template: '<div class="container"><TextBlock v-bind="args" /></div>',
});
export const Lightgrey = Template.bind({});
Lightgrey.args = {
  testVariant: 'rich',
  textContainerWidth: 'wide',
  textContainerPosition: 'left',
  textAlignment: 'left',
  firstButtonContent: 'Mehr Informationen ',
  firstButtonInNewWindow: true,
  secondButtonContent: 'Secondary button',
};

export const HomePage = Template.bind({});
HomePage.args = {
  testVariant: 'home',
  firstButtonContent: 'Mehr Informationen',
};
