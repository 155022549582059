import IconText from './IconText.vue';

export default {
  title: 'Molecules/IconText',
  tags: ['autodocs'],
  component: IconText,
  argTypes: {
    title: { control: 'text' },
    paragraph: { control: 'text' },
    icon: { control: 'select', options: ['leaf', 'phone', 'house', 'smily'] },
    numberOfChildren: { control: { type: 'range', min: 1, max: 8, step: 1 } },
  },
};

const Template = (args) => ({
  components: { IconText },
  setup() {
    return { args };
  },
  template: '<IconText v-bind="args" />',
});

export const Default = Template.bind({});
Default.args = {
  title: 'Nachhaltig',
  paragraph: '100% Ökostrom und CO2 kompensiertes Gas.',
  icon: 'leaf',
  numberOfChildren: 4,
};
