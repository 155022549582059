import type { Meta, StoryObj } from '@storybook/vue3';
// @ts-ignore
import Calculator from './Calculator.vue';
import { within, userEvent } from '@storybook/test';

const meta = {
  title: 'Organisms/Calculator',
  tags: ['autodocs'],
  component: Calculator,
  render: (args: any) => ({
    components: { Calculator },
    setup() {
      return { args };
    },
    template:
      '<div class="bg-grey" style="padding:50px"><Calculator v-bind="args" /></div>',
  }),
  argTypes: {
    width: {
      name: 'View Type',
      type: 'select',
      defaultValue: 'half',
      table: {
        type: { summary: 'string' },
        defaultValue: { summary: 'half' },
      },
      options: ['half', 'full'],
    },
    customerOptions: {
      type: 'select',
      name: 'Customer Type',
      options: ['b2c', 'b2b', 'b2c_b2b'],
    },
    theme: {
      name: 'Theme',
      type: 'select',
      options: ['dark', 'light', 'grey'],
    },
    componentId: {
      table: {
        disable: true,
      },
    },
    productTypes: {
      table: {
        disable: true,
      },
    },
    promotionCode: {
      table: {
        disable: true,
      },
    },
    disableVouchers: {
      table: {
        disable: true,
      },
    },
    onlyThisPage: {
      table: {
        disable: true,
      },
    },
  },
  args: {
    disableVouchers: '1',
    promotionCode: '0',
    onlyThisPage: '0',
  },
} satisfies Meta<typeof Calculator>;

export default meta;
type Story = StoryObj<typeof meta>;

export const HalfView: Story = {
  ...meta,
  args: {
    ...meta.args,
    width: 'half',
    theme: 'dark',
    customerOptions: 'b2c_b2b',
    componentId: '123',
    productTypes: {
      '01': {
        type: '01',
        typeReadable: 'power',
        isSelected: true,
        redirectPage: 'strom/preisberechnung',
        badge: 'Bis zu </br><b>250€</b>Bonus',
      },
      '02': {
        type: '02',
        typeReadable: 'gas',
        isSelected: false,
        redirectPage: 'gas/preisberechnung',
        badge: 'Bis zu </br><b>350€</b>Bonus',
      },
      '03': {
        type: '03',
        typeReadable: 'heatflow',
        isSelected: false,
        redirectPage: 'waermestrom/preisberechnung',
      },
    },
  },
};

// ... Rest of the stories following the same pattern ...
export const FullView: Story = {
  ...meta,
  args: {
    width: 'full',
    theme: 'dark',
    productTypes: {
      '01': {
        type: '01',
        typeReadable: 'power',
        isSelected: true,
        redirectPage: 'strom/preisberechnung',
        badge: 'Bis zu </br><b>250€</b>Bonus',
      },
      '02': {
        type: '02',
        typeReadable: 'gas',
        isSelected: false,
        redirectPage: 'gas/preisberechnung',
        badge: 'Bis zu </br><b>350€</b>Bonus',
      },
      '03': {
        type: '03',
        typeReadable: 'heatflow',
        isSelected: false,
        redirectPage: 'waermestrom/preisberechnung',
      },
    },
    customerOptions: 'b2c_b2b',
  },
};

export const GreyTheme: Story = {
  ...meta,
  args: {
    width: 'full',
    theme: 'grey',
    productTypes: {
      '01': {
        type: '01',
        typeReadable: 'power',
        isSelected: true,
        redirectPage: 'strom/preisberechnung',
        badge: 'Bis zu </br><b>250€</b>Bonus',
      },
      '02': {
        type: '02',
        typeReadable: 'gas',
        isSelected: false,
        redirectPage: 'gas/preisberechnung',
        badge: 'Bis zu </br><b>350€</b>Bonus',
      },
      '03': {
        type: '03',
        typeReadable: 'heatflow',
        isSelected: false,
        redirectPage: 'waermestrom/preisberechnung',
      },
    },
    customerOptions: 'b2c_b2b',
  },
};

export const LightTheme: Story = {
  ...meta,
  args: {
    width: 'full',
    theme: 'light',
    productTypes: {
      '01': {
        type: '01',
        typeReadable: 'power',
        isSelected: true,
        redirectPage: 'strom/preisberechnung',
        badge: 'Bis zu </br><b>250€</b>Bonus',
      },
      '02': {
        type: '02',
        typeReadable: 'gas',
        isSelected: false,
        redirectPage: 'gas/preisberechnung',
        badge: 'Bis zu </br><b>350€</b>Bonus',
      },
      '03': {
        type: '03',
        typeReadable: 'heatflow',
        isSelected: false,
        redirectPage: 'waermestrom/preisberechnung',
      },
    },
    customerOptions: 'b2c_b2b',
  },
};

export const FourProducts: Story = {
  ...meta,
  args: {
    width: 'full',
    theme: 'dark',
    productTypes: {
      '01': {
        type: '01',
        typeReadable: 'power',
        isSelected: false,
        redirectPage: 'strom/preisberechnung',
        badge: 'Bis zu </br><strong>300€</strong>Bonus',
      },
      '02': {
        type: '02',
        typeReadable: 'gas',
        isSelected: true,
        redirectPage: 'gas/preisberechnung',
        badge: 'Bis zu </br><strong>350€</strong>Bonus',
      },
      '03': {
        type: '03',
        typeReadable: 'heatflow',
        isSelected: false,
        redirectPage: 'waermestrom/preisberechnung',
        badge: 'Bis zu </br><strong>300€</strong>Bonus',
      },
      '05': {
        type: '05',
        typeReadable: 'dynamic_strom',
        isSelected: false,
        redirectPage: 'strom/preisberechnung',
      },
    },
    customerOptions: 'b2c_b2b',
  },
};

export const SingleProduct: Story = {
  ...meta,
  args: {
    width: 'full',
    theme: 'dark',
    productTypes: {
      '01': {
        type: '01',
        typeReadable: 'power',
        isSelected: true,
        redirectPage: 'strom/preisberechnung',
        badge: 'Bis zu </br><strong>300€</strong>Bonus',
      },
    },
    customerOptions: 'b2c_b2b',
  },
};

export const SinglePowerReduced: Story = {
  ...meta,
  args: {
    width: 'full',
    theme: 'dark',
    reduced: '1',
    productTypes: {
      '01': {
        type: '01',
        typeReadable: 'power',
        isSelected: true,
        redirectPage: 'strom/preisberechnung',
        badge: 'Bis zu </br><strong>300€</strong>Bonus',
      },
    },
    customerOptions: 'b2c_b2b',
  },
};

export const SingleGasReduced: Story = {
  ...meta,
  args: {
    width: 'full',
    theme: 'dark',
    reduced: '1',
    productTypes: {
      '02': {
        type: '02',
        typeReadable: 'gas',
        isSelected: true,
        redirectPage: 'gas/preisberechnung',
        badge: 'Bis zu </br><strong>350€</strong>Bonus',
      },
    },
    customerOptions: 'b2c_b2b',
  },
};

export const SingleHeatflowReduced: Story = {
  ...meta,
  args: {
    width: 'full',
    theme: 'dark',
    reduced: '1',
    productTypes: {
      '03': {
        type: '03',
        typeReadable: 'heatflow',
        isSelected: true,
        redirectPage: 'waermestrom/preisberechnung',
      },
    },
    customerOptions: 'b2c_b2b',
  },
};

export const InteractivePower: Story = {
  ...FullView,
  args: {
    ...FullView.args,
  },
  play: async ({ canvasElement }: { canvasElement: HTMLElement }) => {
    const canvas = within(canvasElement);
    const powerTabButton = canvas.getByRole('tab', { name: 'Ökostrom' });
    await userEvent.click(powerTabButton);
    const radioBusinessButton = canvas.getByRole('radio', {
      name: 'Geschäftskunden',
    });
    await userEvent.click(radioBusinessButton);

    const zipInput = canvas.getByTestId('zip-single-option');
    await userEvent.click(zipInput);
    await userEvent.type(zipInput, '12345', {
      delay: 100,
    });

    const sliderInput = canvas.getByRole('slider');

    await userEvent.pointer({ keys: '[MouseLeft]' });
    await userEvent.pointer(
      [
        {
          target: sliderInput,
          coords: { x: 100, y: 10 },
          keys: '[MouseLeft>]',
        },
        {
          target: sliderInput,
          coords: { x: 200, y: 10 },
          keys: '[MouseLeft>]',
        },
        {
          target: sliderInput,
          coords: { x: 300, y: 10 },
          keys: '[MouseLeft>]',
        },
        {
          target: sliderInput,
          coords: { x: 400, y: 10 },
          keys: '[MouseLeft>]',
        },
        {
          target: sliderInput,
          coords: { x: 500, y: 10 },
          keys: '[MouseLeft>]',
        },

        { keys: '[/MouseLeft]' },
      ],
      { delay: 200 },
    );
    const submitButton = canvas.getAllByText('Strompreis berechnen');
    await userEvent.click(submitButton[0]);
  },
};

export const InteractiveGas: Story = {
  ...FullView,
  args: {
    ...FullView.args,
  },
  play: async ({ canvasElement }: { canvasElement: HTMLElement }) => {
    const canvas = within(canvasElement);
    const gasTabButton = canvas.getByRole('tab', { name: 'Gas' });
    await userEvent.click(gasTabButton);
  },
};
